<template>
  <div class="file">
    <div class="file__header">
      <div class="header__img">
        <img
          class="logo-img"
          src="@/assets/img/icons/magnum-pdf.svg"
          alt="Логотип"
          width="221"
          height="40"
        />
        <div class="header__img-location">
          <img src="@/assets/icons/location.svg" alt="" />
          <p>Алматы</p>
        </div>
      </div>
      <div class="file__catalog" :class="{ safari: isSafari }">
        <div class="file__catalog-up">Каталог скидок</div>
        <div class="file__catalog-down">
          <p>Дата скачивания</p>
          <p>{{ date }}</p>
        </div>
      </div>
    </div>
    <div v-if="pdfProducts.length">
      <div
        v-for="(item, index) in pdfProducts"
        :key="index"
        class="file__body"
        :class="{ safari: isSafari }"
      >
        <product-card
          v-for="product in item.data"
          :key="product.id"
          :item="product"
          :isPdfProduct="true"
        />
      </div>
    </div>
    <table v-if="allProducts.length" class="file__table">
      <tr class="file__table-header">
        <th>тип скидки</th>
        <th>наименование</th>
        <th>категория</th>
        <th>старая цена</th>
        <th>новая цена</th>
        <th>скидка</th>
        <th>скидка действует до</th>
      </tr>
      <tr
        v-for="(item, index) in allProducts"
        :key="index"
        class="file__table-row"
      >
        <td>{{ getDiscountType(item) }}</td>
        <td>{{ item.attributes.name }}</td>
        <td>{{ item.attributes.category.data.attributes.label }}</td>
        <td>
          {{
            item.attributes.start_price == 0
              ? "--------"
              : item.attributes.start_price
          }}
        </td>
        <td>{{ item.attributes.final_price }}</td>
        <td>
          {{ item.attributes.discount === 0 ? "ттт" : getDiscount(item) }}
        </td>
        <td>{{ getDate(item) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import ProductCard from "@/components/products/ProductCard.vue";
export default {
  components: { ProductCard },
  name: "PdfCatalog",
  props: {
    pdfProducts: {
      type: Array,
      required: true,
    },
    allProducts: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.checkBrowser();
  },
  data() {
    return {
      date: new Date().toLocaleDateString(),
      isSafari: false,
    };
  },
  methods: {
    checkBrowser() {
      let userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detection";
      }

      if (browserName === "safari") {
        this.isSafari = true;
      } else {
        this.isSafari = false;
      }
    },
    getDiscount(item) {
      if (!item.attributes.best_price) {
        return "-" + parseInt(item.attributes.discount * 100) + "%";
      }
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes?.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }).format(dateFromStr);
    },
    getDiscountType(item) {
      return item?.attributes?.discount_type?.data?.attributes?.label;
    },
  },
};
</script>

<style lang="scss">
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.file {
  background: -o-radial-gradient(50% 50%, 50% 50%, #af71ff 0%, #6e2dc3 100%);
  background: radial-gradient(50% 50% at 50% 50%, #af71ff 0%, #6e2dc3 100%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  padding: 26px 20px;
  .product-block {
    // max-height: 100%;
    padding: 15px 12px 45px 15px;
    width: auto !important;
  }
  .product-block__price-wrapper {
    bottom: 0;
  }
  .product-block__descr {
    font-size: 12px;
    margin-bottom: 0;
  }

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    .header__img {
      &-location {
        margin-top: 14px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  &__catalog {
    color: #fff;
    font-weight: 700;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    &.safari {
      .file__catalog-up {
        font-size: 20px;
      }
      .file__catalog-down {
        font-size: 12px;
        width: 150px;
      }
    }
    &-up {
      background-color: #f50f64;
      font-size: 24px;
      border-radius: 10px;
      width: 208px;
      padding: 11px;
      -webkit-transform: rotate(-2deg);
      -ms-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
    &-down {
      background-color: #59cbe8;
      font-size: 14px;
      border-radius: 10px;
      width: 138px;
      padding: 6px 11px;
      -webkit-transform: rotate(2deg);
      -ms-transform: rotate(2deg);
      transform: rotate(2deg);
    }
  }
  .product-block {
    max-width: 250px;
  }
  &__body {
    margin-top: 32px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    margin-bottom: 200px;
    padding-top: 80px;

    &:first-child {
      padding-top: 0;
    }

    &.safari {
      margin-top: 30px;
      display: grid;
      gap: 10px;
      padding-top: 30px;
      grid: 40vh 40vh 40vh / repeat(3, 1fr);
      .product-block__img {
        height: 100px;
        img {
          object-fit: contain;
        }
      }
      &:first-child {
        padding-top: 0;
        grid: 35vh 35vh 35vh / repeat(3, 1fr);
      }
    }
  }

  &__table {
    margin-top: 100px;
    background-color: #fff;
    width: 100%;
    text-align: left;
    font-size: 10px;
    th {
      color: #f21066;
      border: 1px solid #000;
      padding: 0 10px;
    }

    tr {
      border: 1px solid #000;
    }

    td {
      padding: 0 10px;
      border: 1px solid #000;
    }
  }
}

@media print {
  .header {
    display: none;
  }
  .header-nav {
    display: none;
  }
}

@media (max-width: 500px) {
  .file {
    &__body {
      &.safari {
        margin-top: 30px;
        display: grid;
        gap: 10px;
        padding-top: 30px;
        margin-bottom: 0;
        grid: 33vh 33vh 33vh / repeat(3, 1fr);
        .product-block__img {
          height: 100px;
          img {
            object-fit: contain;
          }
        }
        &:first-child {
          padding-top: 0;
          grid: 30vh 30vh 30vh / repeat(3, 1fr);
        }
      }
    }
  }
}
</style>
